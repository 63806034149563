<template>
  <li>
    <div class="report-icon-block">
      <img src="@/assets/images/report.svg" alt="" class="report-icon" />
    </div>
    <div class="pdf-links">
      {{ title }}
    </div>
    <a
      href="javascript:void(0)"
      class="report-download-btn"
      v-b-tooltip.hover
      title="Download"
      @click="clickHandler"
    >
      <i :class="isDownload ? 'fa fa-circle-o-notch fa-spin' : 'fas fa-download'" />
    </a>
  </li>
</template>
<script>
export default {
  name: "ResoucreRepReportListBlock",
  props: {
    title: {
      required: true,
    },
    isDownload: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    clickHandler() {
      this.$emit("onClicked");
    },
  },
};
</script>
