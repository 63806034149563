var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "personalizedSiteSendEmail",
      "modal-class": "client-details-modal",
      "centered": "",
      "size": "lg",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "view-clients-modal-content"
  }, [_c('h6', [_vm._v("Email Member")]), _c('div', {
    staticClass: "addMessage-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "sendPersonalizedEmailContainer",
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.sendEmail);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true,
              neverbounce: true
            },
            "labelFor": "email",
            "labelName": "Email",
            "inputName": "email",
            "vmodel": _vm.form.email,
            "formGroupClass": "col-xxl-6 mb-3",
            "inputType": "email"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "email", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "subject",
            "labelName": "Subject",
            "inputName": "subject",
            "vmodel": _vm.form.subject,
            "formGroupClass": "col-xxl-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "subject", $event);
            }
          }
        }), _c('div', {
          staticClass: "col-xxl-12 mb-3"
        }, [_c('validation-provider', {
          attrs: {
            "name": "Message",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('label', [_vm._v(" Message "), _c('span', {
                staticClass: "required-indc"
              }, [_vm._v("*")])]), _c('ckeditor', {
                attrs: {
                  "editor": _vm.editor,
                  "config": _vm.editorConfig
                },
                model: {
                  value: _vm.form.message,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "message", $$v);
                  },
                  expression: "form.message"
                }
              }), _c('div', {
                staticClass: "invalid-block invalid-feedback"
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
            }
          }], null, true)
        })], 1)], 1), _c('div', {
          staticClass: "row justify-content-md-center"
        }, [_c('div', {
          staticClass: "col-xxl-5 mb-4"
        }, [_c('b-button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit",
            "disabled": _vm.isSend
          }
        }, [_vm.isSend ? [_vm._v(" Sending ... ")] : [_vm._v(" Send ")]], 2)], 1)]), _c('div', {
          staticClass: "col-xxl-12 mb-3 modal-inner-bg"
        }, [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("From:")]), _c('p', [_c('strong', [_vm._v(" " + _vm._s(_vm.rep.name))])]), _vm.rep.code ? _c('p', [_vm._v(_vm._s(_vm.rep.code))]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.rep.email))]), _c('p', [_vm._v(_vm._s(_vm.rep.phone))])])])];
      }
    }])
  })], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }