var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "message-list-content"
  }, [_vm.type == 'receive' ? [_c('div', {
    staticClass: "msg-userIcon"
  }, [_c('img', {
    attrs: {
      "src": _vm.image,
      "alt": ""
    }
  })])] : [_vm._m(0)], _c('div', {
    staticClass: "msgShort-detail"
  }, [_vm.type == 'receive' ? _c('span', [_vm._v(" " + _vm._s(_vm.row.sender_details.name) + " - " + _vm._s(_vm.row.from) + " ")]) : _vm._e(), _c('h3', [_vm._v(_vm._s(_vm._f("truncate")(_vm.row.subject, 50)))]), _c('p', {
    domProps: {
      "innerHTML": _vm._f("truncate")(_vm.strippedHtmlMessage, 100)
    }
  }), _c('p', {
    staticClass: "message-list-date"
  }, [_c('i', {
    staticClass: "far fa-calendar-alt"
  }), _vm._v(" " + _vm._s(_vm._f("date_format")(_vm.row.date_created, "MM/DD/YYYY HH:mm:ss", "MM/DD/YYYY")) + " ")])])], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "msg-userIcon send-msg-icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/shortmessage.svg")
    }
  })]);
}]

export { render, staticRenderFns }