var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {
    staticClass: "dashmessage-wrap"
  }, [_c('div', {
    staticClass: "dashmessage-card-title"
  }, [_c('h2', [_vm._v("Message Center")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'AdminMessage',
        params: {
          tabIndex: 1
        }
      }
    }
  }, [_vm._v(" View All ")])], 1), _c('div', {
    staticClass: "dashmessage-card bg-card rep-dash"
  }, [_c('vue-scroll', {
    attrs: {
      "ops": _vm.msgScroll
    }
  }, [_c('ul', [!_vm.rows || _vm.rows.length < 1 ? _c('li', [_c('no-content', {
    attrs: {
      "text": _vm.messageText
    }
  })], 1) : _vm._e(), _vm._l(_vm.rows, function (row) {
    return _c('li', {
      key: row.mid
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'AdminMessage',
          params: {
            tabIndex: 1,
            selectedReceivedMessage: row
          }
        }
      }
    }, [_c('message-list-content', {
      attrs: {
        "row": row,
        "type": "receive"
      }
    })], 1)], 1);
  })], 2)])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }