<template>
  <b-modal :id="modalId" class="resource-modal" centered size="md" hide-footer>
    <template #modal-header>
      <div class="resources-header">
        <h4>{{ modalTitle }}</h4>
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="resources-modal-content resource-common-modal">
        <vue-scroll :ops="dataScroll">
          <ul>
            <li v-for="(b, index) in modalData" :key="index">
              <div class="dashres-icon">
                <img :src="getImageIcon(b.url)" alt="" />
              </div>
              <div class="pdf-links">
                <a :href="b.url" target="_blank">{{ b.filename }}</a>
              </div>
            </li>
          </ul>
        </vue-scroll>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import pdfIcon from "@/assets/images/pdf-file.svg"
import mp4Icon from "@/assets/images/video.svg"
import contractIcon from "@/assets/images/contract.svg"
export default {
  name: "ResourceCommonModal",
  props: {
    modalId: {
      required: true,
    },
    modalData: {
      required: true,
    },
    modalTitle: {
      required: true,
    },
  },
  data: () => ({
    dataScroll: {
      vuescroll: {
        mode: "native",
        wheelScrollDuration: "700",
      },
      scrollPanel: {
        maxHeight: 400,
      },
      bar: {
        background: "#ddd",
      },
    },
    pdfIcon,
    contractIcon,
    mp4Icon
  }),
  methods: {
    getImageIcon(file) {
      let ext = "";
      if (file) {
        ext = file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2);
      }
      switch (ext) {
        case "pdf":
          return pdfIcon
        case "mp4":
          return mp4Icon
        default:
          return contractIcon
      }
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
      // this.$emit('onCloseModal')
    },
  },
};
</script>