import { render, staticRenderFns } from "./DashMessages.vue?vue&type=template&id=574841c4&scoped=true&"
import script from "./DashMessages.vue?vue&type=script&lang=js&"
export * from "./DashMessages.vue?vue&type=script&lang=js&"
import style0 from "./DashMessages.vue?vue&type=style&index=0&id=574841c4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574841c4",
  null
  
)

export default component.exports