<template>
  <a @click.prevent="clickHandler" href="javascript:void(0)">
    <div class="dashres-icon">
      <img :src="image" alt="" />
    </div>
    <div class="dashres-content">
      <h3>{{title}}</h3>
    </div>
  </a>
</template>
<script>
export default {
  name: "DashboardListBlock",
  props: {
    imageName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    image() {
      return require(`@/assets/images/${this.imageName}`);
    },
  },
  methods:{
    clickHandler(){
      this.$emit("onClick")
    }
  }
};
</script>