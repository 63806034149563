<template>
  <div class="col-xxl-3 col-xl-3 col-md-6 col-sm-6 mb-5">
    <div class="dashknowledge-wrap dash-recent-activity">
      <div class="dashknowledge-card-title">
        <h2>Recent Activity</h2>
        <router-link
          :to="{
            name: 'Notification',
          }"
        >
          View All
        </router-link>
      </div>
      <div class="dashknowledge-card">
        <vue-scroll :ops="notificationScroll">
          <ul v-if="notifications || notifications.records.length > 0">
            <notification-list
              v-for="k in notifications.records"
              :key="k.unique_identifier"
              :list="k"
              @onClicked="showNotificationData(k)"
            />
          </ul>
          <ul v-else>
            <li>
              <no-content text="No recent activities found." />
            </li>
          </ul>
        </vue-scroll>
      </div>
    </div>
    <notification-view
      :notification="notification"
      @onCloseModal="notification = null"
    />
  </div>
</template>
<script>
import Api from "@/resource/Api";
import NotificationList from "@/views/Notification/partials/NotificationList";
import NotificationView from "@/views/Notification/partials/NotificationView";
export default {
  name: "DashNotification",
  components: { NotificationList, NotificationView },
  data: () => ({
    notificationScroll: {
      vuescroll: {
        mode: "native",
        wheelScrollDuration: "700",
      },
      scrollPanel: {
        maxHeight: 400,
        speed: 2000,
      },
      bar: {
        background: "#ddd",
      },
    },
    notifications: [],
    notification: "",
  }),
  methods: {
    showNotificationData(row) {
      this.notification = row;
      this.$bvModal.show("viewNotification");
    },
    getNotifications() {
      const url = "v1/read-unread-notification?page_size=10&page=1";
      Api.get(url)
        .then((res) => {
          this.notifications = res.data;
        })
        .catch(() => {
          this.notifications = [];
        });
    },
  },
  created() {
    this.getNotifications();
  },
};
</script>
<style scoped>
.knowledge-list {
  cursor: pointer;
}
</style>