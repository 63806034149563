<template>
    <b-modal :id="modalId" class="resource-modal" centered size="md" hide-footer @shown="getData">
      <template #modal-header>
        <div class="resources-header">
          <h4>{{ modalTitle }}</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="resources-modal-content resource-common-modal">
          <vue-scroll :ops="dataScroll">
            <ul>
              <li class="d-flex align-items-center" v-for="(b, index) in contracts" :key="index">
                <div class="dashres-icon">
                  <img :src="pdfIcon" alt="" />
                </div>
                <div style="width: 80%;" class="pdf-links d-flex justify-content-between">
                  <span>{{ b.contract_type.charAt(0).toUpperCase() + b.contract_type.slice(1) }}</span>
                  <template v-if="b.screenshots">
                    <custom-button
                    title="Download Document"
                    iconClass="fas fa-download"
                    @onClicked="downloadHandler(b.screenshots)"
                  />
                  </template>
                  
                </div>
              </li>
            </ul>
          </vue-scroll>
        </div>
      </b-container>
    </b-modal>
  </template>
  <script>
  import pdfIcon from "@/assets/images/pdf-file.svg"
  import Api from "@/resource/Api";
  export default {
    
    name: "DownloadContractModal",
    props: {
      modalId: {
        required: true,
      },
      modalTitle: {
        required: true,
      },
    },
    data: () => ({
      dataScroll: {
        vuescroll: {
          mode: "native",
          wheelScrollDuration: "700",
        },
        scrollPanel: {
          maxHeight: 400,
        },
        bar: {
          background: "#ddd",
        },
      },
      pdfIcon,
      contracts:[],
      apiUrl: process.env.VUE_APP_FILE_DOWNLOAD_URL
    }),
    methods: {
      closeModal() {
        this.$bvModal.hide(this.modalId);
      },
      downloadHandler(url) {
      let downLoadLink = `${this.apiUrl}${url}`
      window.open(downLoadLink, '_blank');
    },
      getData() {
      // const agentId = 10109;
      const url = `v2/rep-contract/get-signed-contract?agent_id=${this.$store.getters.repId}`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          this.contracts= data;
          
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
    },
  
  };
  </script>

