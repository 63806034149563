var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_vm._m(0), _c('div', {
    staticClass: "pdf-links"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "report-download-btn",
    attrs: {
      "href": "javascript:void(0)",
      "title": "Download"
    },
    on: {
      "click": _vm.clickHandler
    }
  }, [_c('i', {
    class: _vm.isDownload ? 'fa fa-circle-o-notch fa-spin' : 'fas fa-download'
  })])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "report-icon-block"
  }, [_c('img', {
    staticClass: "report-icon",
    attrs: {
      "src": require("@/assets/images/report.svg"),
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }