var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-md-6 col-sm-6 mb-5"
  }, [_c('div', {
    staticClass: "dashknowledge-wrap dash-recent-activity"
  }, [_c('div', {
    staticClass: "dashknowledge-card-title"
  }, [_c('h2', [_vm._v("Recent Activity")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Notification'
      }
    }
  }, [_vm._v(" View All ")])], 1), _c('div', {
    staticClass: "dashknowledge-card"
  }, [_c('vue-scroll', {
    attrs: {
      "ops": _vm.notificationScroll
    }
  }, [_vm.notifications || _vm.notifications.records.length > 0 ? _c('ul', _vm._l(_vm.notifications.records, function (k) {
    return _c('notification-list', {
      key: k.unique_identifier,
      attrs: {
        "list": k
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.showNotificationData(k);
        }
      }
    });
  }), 1) : _c('ul', [_c('li', [_c('no-content', {
    attrs: {
      "text": "No recent activities found."
    }
  })], 1)])])], 1)]), _c('notification-view', {
    attrs: {
      "notification": _vm.notification
    },
    on: {
      "onCloseModal": function onCloseModal($event) {
        _vm.notification = null;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }