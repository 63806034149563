var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    staticClass: "resource-modal",
    attrs: {
      "id": _vm.modalId,
      "centered": "",
      "size": "md",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "resources-header"
        }, [_c('h4', [_vm._v(_vm._s(_vm.modalTitle))]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "resources-modal-content resource-common-modal"
  }, [_c('vue-scroll', {
    attrs: {
      "ops": _vm.dataScroll
    }
  }, [_c('ul', _vm._l(_vm.modalData, function (b, index) {
    return _c('li', {
      key: index
    }, [_c('div', {
      staticClass: "dashres-icon"
    }, [_c('img', {
      attrs: {
        "src": _vm.getImageIcon(b.url),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "pdf-links"
    }, [_c('a', {
      attrs: {
        "href": b.url,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(b.filename))])])]);
  }), 0)])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }