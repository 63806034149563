<template>
  <div v-if="video" controls>
    <b-button variant="close-btn play-video-close-btn" title="Close Video" @click="closeVideo">
      <i class="fas fa-times"></i>
    </b-button>
    <b-embed
      type="video"
      aspect="1by1"
      controls
      :poster="video.thumbnail_url"
      class="play-video"
    >
      <source :src="video.video_url" />
    </b-embed>
  </div>
</template>

<script>
export default {
  name: "ResourceVideoPlayer",
  props: {
    video: {
      required: true,
    },
  },
  methods:{
    closeVideo(){
      this.$emit("update:video", null);
    }
  }
};
</script>
<style>
.play-video {
  width: 700px;
  height: 300px;
}
.play-video-close-btn{
    position: absolute;
    top: -15px;
    right: 50px;
    background: #fff;
    color: #000;
    border: 0;
    box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
    border-radius: 60px;
    font-size: 18px;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
}
</style>
