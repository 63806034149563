<template>
  <div class="col-xxl-3 col-xl-3 col-md-6 col-sm-6 mb-4">
    <div class="dashpersonalized-wrap">
      <div class="dashpersonalized-card-title">
        <h2>Personalized Sites </h2>
        <custom-button iconClass="fas fa-edit" @onClicked="editSite" />
      </div>
      <div class="dashpersonalized-card dashpersonalized-body">
        <vue-scroll :ops="prsnlScroll">
          <ul>
            <li
              v-for="(site, index) in sites"
              :key="index"
              @click="activeList = index"
              :class="activeList == index ? 'active' : ''"
            >
              <div class="dashprsnl-logo">
                <img :src="site.image" alt="" class="img-fluid" />
              </div>
              <div class="dashprsnl-content">
                <h3>{{ site.name }}</h3>
                <span class="url-site">
                  {{ site.domain }}
                </span>
              </div>
              <div class="click-toggler">
                <a class="visit-portal" :href="site.domain" target="_blank">
                  Visit Portal
                </a>
                <a
                  class="send-email"
                  @click="sendPersonalizedEmail(site.domain)"
                >
                  <span> Send Email </span>
                </a>
                <a class="copy-link">
                  <span class="code" @click.prevent="copyLink(site.domain)">
                    Copy Link
                  </span>
                </a>
                <!-- <a class="copy-link">
                  <span class="code" @click.prevent="downloadQR(site.domain, site.name)">
                    QR
                  </span>
                </a> -->
                
                <a class="copy-link" v-b-modal.QRModal>
                  <span class="code" @click.prevent="downloadQR(site.domain, site.name)">
                    <i class="fas fa-qrcode"></i> QR Code
                  </span>
                </a>
              </div>
            </li>
          </ul>
        </vue-scroll>
      </div>
      <personalized-site-edit
        :web-access-code.sync="webAccessCode"
        :updateUrl="updateLandingPageUrl"
        @onSuccess="getSites"
      />
      <personalized-site-send-email-modal
        :selected-link="selectedLink"
        @onCloseModal="selectedLink = ''"
        :sendQR="sendQR"
      />
    </div>
    <!-- QR Modal -->
    <b-modal 
      id="QRModal" 
      centered
      hide-footer
      >
      <template #modal-header>
        <div class="view-clients-header">
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="view-clients-modal-content">
          <div class="repQR">
            <img :src="QRUrl" alt="">
            <h4>{{domainName}}</h4>
            <p>{{domainURL}}</p>
          </div>
          <div class="repInformation">
            <div>
              <h3>{{ $store.getters.rep.name }}</h3>
              <span class="repCode">{{ $store.getters.rep.code }}</span>
            </div>
            <div class="downloadButton" style="margin-right:-15%">
              <button  @click="sendPersonalizedEmail(domainURL, 1)"><i class="fas fa-share-alt"></i> Share</button>
            </div>
            <div class="downloadButton">
              <button @click.prevent="downloadFile()"><i class="fas fa-download"></i> Download</button>
            </div>
          </div>
          
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import PersonalizedSiteEdit from "./PersonalizedSiteEdit.vue";
import PersonalizedSiteSendEmailModal from "./PersonalizedSiteSendEmailModal.vue";
import {apiUrl} from "@/resource/EndPoints/endpoints"
export default {
  name: "PersonalizedSites",
  components: {
    PersonalizedSiteEdit,
    PersonalizedSiteSendEmailModal,
  },
  data: () => ({
    prsnlScroll: {
      vuescroll: {
        mode: "native",
        wheelScrollDuration: "700",
      },
      scrollPanel: {
        maxHeight: 400,
      },
      bar: {
        background: "#ddd",
      },
    },
    domainURL: '',
    domainName: '',
    QRUrl: '',
    sites: [],
    webAccessCode: "",
    activeList: null,
    row: null,
    selectedLink: "",
    updateLandingPageUrl: "v2/update-personalized-site",
    sendQR: 0
  }),
  methods: {
    sendPersonalizedEmail(link, sendQR = 0) {
      this.selectedLink = link;
      this.sendQR = sendQR
      this.$bvModal.show("personalizedSiteSendEmail");
    },
    editSite() {
      this.$bvModal.show("updatePersonalizedSite");
    },
    getSites() {
      Api.get("v2/get-personalized-site")
        .then((res) => {
          let data = res.data.data;
          this.sites = data.sites;
          this.webAccessCode = data.web_access_code;
        })
        .catch(() => {
          this.sites = [];
          this.webAccessCode = "";
        });
    },
    copyLink(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$notify("Link Copied Successfully.");
        })
        .catch(() => {
          this.$notify("Failed to copy link.", "error");
        });
    },
    downloadQR(text, name) {
      this.domainURL = text
      this.domainName = name
      Api.get("v2/qr-code/generate?qr_message="+text)
        .then((res) => {
          //let data = res.data.data.qr_code;
          /*window.open(res.data.data.qr_code,'qr_code.svg');*/
         // window.open(res.data.data.url);
         
          //const downloadUrl = apiUrl.replace('/api/','/download-file')
          //const newUrl = `${downloadUrl}?file_url=${res.data.data.url}&file_name=`+name
          this.QRUrl = res.data.data.url
          this.domainName = name
          ///console.log(name)
          //window.open(newUrl,'_blank')
        })
        .catch(() => {
        });
    },
    downloadFile() {
      const downloadUrl = apiUrl.replace('/api/','/download-file')
      const newUrl = `${downloadUrl}?file_url=${this.QRUrl}&file_name=`+this.domainName
      window.open(newUrl,'_blank')
    },
    openModal(row, modalId) {
      this.row = row;
      this.$bvModal.show(modalId);
    },
    closeModal(){
      this.$bvModal.hide("QRModal");
    }
  },
  created() {
    this.getSites();
  },
};
</script>
<style src="@/assets/css/viewclients.css"></style>
