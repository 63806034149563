var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    staticClass: "resource-modal",
    attrs: {
      "id": "resourceReportModal",
      "centered": "",
      "size": "lg",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "resources-header"
        }, [_c('h4', [_vm._v("Report")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    ref: "resourceReportContainer",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "resources-modal-content"
  }, [_c('ul', {
    staticClass: "rep-report"
  }, [_vm.isVipUser ? _c('li', [_c('div', {
    staticClass: "report-icon-block",
    domProps: {
      "innerHTML": _vm._s(_vm.imageBlock)
    }
  }), _c('div', {
    staticClass: "pdf-links"
  }, [_vm._v(" All Member Contact Report "), _c('date-picker', {
    staticClass: "all-contact-datepicker",
    attrs: {
      "placeholder": "Select Month",
      "type": "month",
      "value-type": "format",
      "format": "YYYY-MM",
      "default-value": new Date(),
      "disabled-date": _vm.disabledAfterToday
    },
    model: {
      value: _vm.selectedAllReportDate,
      callback: function callback($$v) {
        _vm.selectedAllReportDate = $$v;
      },
      expression: "selectedAllReportDate"
    }
  })], 1), _vm.selectedAllReportDate ? _c('resource-report-download-button', {
    attrs: {
      "isDownload": _vm.memberContactReport == 'ALL'
    },
    on: {
      "onClicked": function onClicked($event) {
        _vm.downloadReport();

        _vm.memberContactReport = 'ALL';
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _c('li', [_c('div', {
    staticClass: "report-icon-block",
    domProps: {
      "innerHTML": _vm._s(_vm.imageBlock)
    }
  }), _c('div', {
    staticClass: "pdf-links"
  }, [_vm._v(" Member Contact Report ( "), !_vm.isVipUser ? _c('span', [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": function click($event) {
        return _vm.downloadReport();
      }
    }
  }, [_vm._v(" All ")]), _vm._v(" | ")]) : _vm._e(), _c('a', {
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": function click($event) {
        return _vm.downloadReport('ACTIVE');
      }
    }
  }, [_vm._v(" Active ")]), _vm._v(" | "), _c('a', {
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": function click($event) {
        return _vm.downloadReport('TERMED');
      }
    }
  }, [_vm._v(" Termed ")]), _vm._v(" ) ")])]), _c('li', [_c('div', {
    staticClass: "report-icon-block",
    domProps: {
      "innerHTML": _vm._s(_vm.imageBlock)
    }
  }), _c('div', {
    staticClass: "pdf-links"
  }, [_vm._v(" Active Member Report Primary Only "), _c('br'), _c('multiselect', {
    attrs: {
      "placeholder": "Select Effective Date",
      "options": _vm.effectiveDates
    },
    model: {
      value: _vm.date1,
      callback: function callback($$v) {
        _vm.date1 = $$v;
      },
      expression: "date1"
    }
  })], 1), _c('resource-report-download-button', {
    attrs: {
      "isDownload": _vm.memberContactReport == 'MEMBER_PRIMARY' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadContactReport('MEMBER_PRIMARY');
      }
    }
  })], 1), _c('li', [_c('div', {
    staticClass: "report-icon-block",
    domProps: {
      "innerHTML": _vm._s(_vm.imageBlock)
    }
  }), _c('div', {
    staticClass: "pdf-links"
  }, [_vm._v(" Active Member Report with Dependents "), _c('br'), _c('div', {
    staticClass: "dependent-date"
  }, [_vm.isVipUser ? _c('date-picker', {
    staticClass: "all-contact-datepicker",
    attrs: {
      "placeholder": "Select Month",
      "type": "month",
      "value-type": "format",
      "format": "YYYY-MM",
      "default-value": new Date(),
      "disabled-date": _vm.disabledAfterToday
    },
    model: {
      value: _vm.selectedDependentDate,
      callback: function callback($$v) {
        _vm.selectedDependentDate = $$v;
      },
      expression: "selectedDependentDate"
    }
  }) : _vm._e(), !_vm.isVipUser ? _c('multiselect', {
    attrs: {
      "placeholder": "Select Effective Date",
      "options": _vm.effectiveDates
    },
    model: {
      value: _vm.date2,
      callback: function callback($$v) {
        _vm.date2 = $$v;
      },
      expression: "date2"
    }
  }) : _vm._e()], 1)]), _vm.isVipUser ? [_vm.selectedDependentDate ? _c('resource-report-download-button', {
    attrs: {
      "isDownload": _vm.memberContactReport == 'MEMBER_DEPENDENT' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadContactReport('MEMBER_DEPENDENT');
      }
    }
  }) : _vm._e()] : [_c('resource-report-download-button', {
    attrs: {
      "isDownload": _vm.memberContactReport == 'MEMBER_DEPENDENT' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadContactReport('MEMBER_DEPENDENT');
      }
    }
  })]], 2)]), _c('div', [_c('header', {
    staticClass: "modal-header"
  }, [_c('div', {
    staticClass: "resources-header"
  }, [_c('h4', {
    staticClass: "rep"
  }, [_vm._v("Rep Related Reports")])]), _c('i', {
    staticClass: "accordion-icon",
    class: _vm.accordion ? 'fa fa-angle-up' : 'collapsed fa fa-angle-down',
    attrs: {
      "aria-expanded": _vm.accordion ? 'true' : 'false',
      "aria-controls": "collapse-4"
    },
    on: {
      "click": function click($event) {
        _vm.accordion = !_vm.accordion;
      }
    }
  })]), _c('b-collapse', {
    attrs: {
      "id": "accordion-1",
      "accordion": "my-accordion",
      "role": "tabpanel"
    },
    model: {
      value: _vm.accordion,
      callback: function callback($$v) {
        _vm.accordion = $$v;
      },
      expression: "accordion"
    }
  }, [_c('ul', {
    staticClass: "rep-report-list"
  }, [_vm.isVipUser ? _c('resource-rep-report-list-block', {
    attrs: {
      "title": "Reps That Haved Logged Into The Dashboard.",
      "isDownload": _vm.repReportType == 'LOGGED_IN_REPS' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadRepReport('LOGGED_IN_REPS');
      }
    }
  }) : _vm._e(), _vm.isVipUser ? _c('resource-rep-report-list-block', {
    attrs: {
      "title": "Reps With One/More Online Enrollments.",
      "isDownload": _vm.repReportType == 'REP_ONLINE_ENROLLMENT ' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadRepReport('REP_ONLINE_ENROLLMENT ');
      }
    }
  }) : _vm._e(), _vm.isVipUser ? _c('resource-rep-report-list-block', {
    attrs: {
      "title": "Reps With Online Enrollments But Not Logged Into The Dashboard.",
      "isDownload": _vm.repReportType == 'REP_ONLINE_ENROLLMENT_NOT_LOGGED_IN ' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadRepReport('REP_ONLINE_ENROLLMENT_NOT_LOGGED_IN ');
      }
    }
  }) : _vm._e(), _vm.isVipUser ? _c('resource-rep-report-list-block', {
    attrs: {
      "title": "Reps With Online Enrollments And Logged Into The Dashboard.",
      "isDownload": _vm.repReportType == 'REP_ONLINE_ENROLLMENT_LOGGED_IN ' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadRepReport('REP_ONLINE_ENROLLMENT_LOGGED_IN ');
      }
    }
  }) : _vm._e(), _vm.isVipUser ? _c('resource-rep-report-list-block', {
    attrs: {
      "title": "Reps With Paper Enrollments Only.",
      "isDownload": _vm.repReportType == 'REP_PAPER_ENROLLMENT ' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadRepReport('REP_PAPER_ENROLLMENT ');
      }
    }
  }) : _vm._e(), _vm.isVipUser ? _c('resource-rep-report-list-block', {
    attrs: {
      "title": "Reps With Paper Enrollments But Not Logged Into The Dashboard.",
      "isDownload": _vm.repReportType == 'REP_PAPER_ENROLLMENT_NOT_LOGGED_IN ' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadRepReport('REP_PAPER_ENROLLMENT_NOT_LOGGED_IN ');
      }
    }
  }) : _vm._e(), _c('resource-rep-report-list-block', {
    attrs: {
      "title": "Rep Contact Report.",
      "isDownload": _vm.repReportType == 'REP_CONTACT_REPORT' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadRepReport('REP_CONTACT_REPORT');
      }
    }
  }), _c('resource-rep-report-list-block', {
    attrs: {
      "title": "Rep Direct Downline Report.",
      "isDownload": _vm.repReportType == 'REP_DIRECT_DOWNLINE' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadRepReport('REP_DIRECT_DOWNLINE');
      }
    }
  }), _c('resource-rep-report-list-block', {
    attrs: {
      "title": "Rep Non Direct Downline Report.",
      "isDownload": _vm.repReportType == 'REP_NON_DIRECT_DOWNLINE' ? true : false
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.downloadRepReport('REP_NON_DIRECT_DOWNLINE');
      }
    }
  })], 1)])], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }