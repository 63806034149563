<template>
  <div class="">
    <div class="dashmessage-wrap">
      <div class="dashmessage-card-title">
        <h2>Message Center</h2>
        <router-link :to="{ name: 'AdminMessage', params: { tabIndex: 1 } }">
          View All
        </router-link>
      </div>
      <div class="dashmessage-card bg-card rep-dash">
        <vue-scroll :ops="msgScroll">
          <ul>
            <li v-if="!rows || rows.length < 1">
              <no-content :text="messageText" />
            </li>
            <li v-for="row in rows" :key="row.mid">
              <router-link
                :to="{
                  name: 'AdminMessage',
                  params: { tabIndex: 1, selectedReceivedMessage: row },
                }"
              >
                <message-list-content :row="row" type="receive" />
              </router-link>
            </li>
          </ul>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import MessageListContent from "@/views/Message/partials/MessageListContent";
export default {
  name: "DashMessages",
  components: { MessageListContent },
  data: () => ({
    msgScroll: {
      vuescroll: {
        mode: "native",
        wheelScrollDuration: "200",
      },
      scrollPanel: {
        maxHeight: 189,
      },
      bar: {
        background: "#ddd",
      },
    },
    rows: [],
    messageText: "No messages found.",
  }),
  methods: {
    getAll() {
      const url = "v2/get-received-message-list";
      this.messageText = "Loading messages...";
      Api.get(url)
        .then((res) => {
          this.rows = res.data.records.slice(0, 5);
          if (this.rows.length < 1) {
            this.messageText = "No messages found.";
          }
        })
        .catch(() => {
          this.rows = [];
          this.messageText = "No messages found.";
        });
    },
  },
  created() {
    this.getAll();
  },
};
</script>
<style scoped>
.unread {
  background: rgb(255 152 0 / 8%);
}
</style>