var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "view-plan-model-container"
  }, [_c('b-modal', {
    attrs: {
      "id": "viewPlanModal",
      "modal-class": "view-plan-modal",
      "size": "lg",
      "hide-footer": ""
    },
    on: {
      "shown": function shown($event) {
        return _vm.restViewPlanModel();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-plan-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "view-plan-modal-content"
  }, [_c('div', {
    staticClass: "select-container"
  }, [_c('label', {
    staticClass: "required"
  }, [_c('strong', [_vm._v("Choose Platform")])]), _c('div', {
    staticClass: "mt-2"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupId,
      expression: "groupId"
    }],
    staticClass: "select-drop-down",
    class: {
      'form-invalid': _vm.groupInvaild
    },
    attrs: {
      "name": "",
      "id": "group_id"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.groupId = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.checkGroupValidation]
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v(" Select Platform")]), _vm._l(_vm.platformInfo, function (item) {
    return _c('option', {
      key: item.group_id,
      domProps: {
        "value": item.group_id
      }
    }, [_vm._v(_vm._s(item.gname))]);
  })], 2), _vm.groupInvaild ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()])]), _c('div', {
    staticClass: "btn-container"
  }, [_c('button', {
    staticClass: "view-plans",
    on: {
      "click": _vm.routeToPlanView
    }
  }, [_vm._v("View Plans")])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }