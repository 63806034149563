<template>
  <a
    href="javascript:void(0)"
    class="report-download-btn"
    v-b-tooltip.hover
    title="Download"
    @click="onClickHandler"
  >
    <i
      :class="
        isDownload
          ? 'fa fa-circle-o-notch fa-spin'
          : 'fas fa-download'
      "
    />
  </a>
</template>
<script>
export default {
  name: "ResourceReportDownloadButton",
  props: {
    isDownload: {
      required: true,
      type: Boolean,
    },
  },
  methods:{
      onClickHandler(){
          this.$emit("onClicked")
      }
  }
};
</script>