<template>
  <b-modal
    id="viewKnowledge"
    centered
    size="lg"
    hide-footer
    no-close-on-backdrop
  >
    <template #modal-header>
      <div class="addKnowledge-header text-center">
        <h4>Knowledge Detail</h4>
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times" />
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <knowledge-center-content :content="knowledge" />
    </b-container>
  </b-modal>
</template>
<script>
import KnowledgeCenterContent from "@/views/KnowledgeCenter/partials/KnowledgeCenterContent";
export default {
  name: "KnowledgeCenterView",
  components:{KnowledgeCenterContent},
  props: {
    knowledge: {
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("viewKnowledge");
      this.$emit("onCloseModal");
    },
  },
};
</script>
<style scoped>
.addKnowledge-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
}
.addKnowledge-header p {
  font-size: 14px;
  color: #2c3f58;
}
.addKnowledge-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>