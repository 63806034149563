<template>
  <b-modal
    id="viewNotification"
    centered
    size="md"
    modal-class="view-notification-modal"
    hide-footer
    no-close-on-backdrop
  >
    <template #modal-header>
      <div class="addKnowledge-header view-notification-modal">
        <h4>Activity Detail</h4>
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times" />
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <notification-content :content="notification" />
    </b-container>
  </b-modal>
</template>
<script>
import NotificationContent from "@/views/Notification/partials/NotificationContent";
export default {
  name: "NotificationView",
  components:{NotificationContent},
  props: {
    notification: {
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("viewNotification");
      this.$emit("onCloseModal");
    },
  },
};
</script>
<style scoped>
.addKnowledge-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
}
.addKnowledge-header p {
  font-size: 14px;
  color: #2c3f58;
}
.addKnowledge-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}

</style>

<style>
.view-notification-modal .modal-header {
  text-align: center;
  justify-content: center;
  width: 100%;
}
.view-notification-modal .recent-activity-page {
  min-height: 50px;
  padding-top: 5px;
}
.view-notification-modal .modal-body {
  padding-bottom: 30px;
}
</style>