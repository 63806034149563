<template>
  <div class="view-plan-model-container">
    <b-modal
        id="viewPlanModal"
        modal-class="view-plan-modal"
        size="lg"
        hide-footer
        @shown="restViewPlanModel()">
      <template #modal-header>
        <div class="view-plan-header">
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="view-plan-modal-content">
          <div class="select-container">
            <label class="required"><strong>Choose Platform</strong></label>
            <div class="mt-2">
              <select name=""
                      class="select-drop-down"
                      @change="checkGroupValidation"
                      :class="{'form-invalid': groupInvaild}"
                      v-model="groupId" id="group_id">
                <option value="" disabled> Select Platform</option>
                <option v-for="item in platformInfo" :value="item.group_id" v-bind:key="item.group_id">{{item.gname}}</option>
              </select>
              <div class="form-error text-left ml-3 mt-2"  v-if="groupInvaild">
                Field is required
              </div>
            </div>
          </div>
          <div class="btn-container">
            <button class="view-plans" @click="routeToPlanView">View Plans</button>
          </div>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import endpoint from "@/resource/EndPoints/endpoints"
export default {
  name: "ViewPlansModal",
  props: {
    platformInfo: {
      required: true,
    },
    repInfo: {
      required: true
    }
  },
  data () {
    return {
      groupId: '',
      groupInvaild: false,
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("viewPlanModal");
      this.$emit("onClosedModal");
    },
    restViewPlanModel () {
      this.groupInvaild = false
      this.platformInvaild = false
      this.groupId = ''
      this.platformId =''
    },
    routeToPlanView () {
      if (this.groupId == '') {
        this.groupInvaild = true
      }

      if (this.groupId) {
        let benefitStoreLink
        let agentIdEncrypted = btoa(this.repInfo.id)
        let platformId = this.groupId
        benefitStoreLink = `${endpoint.BENEFIT_STORE_URL}/view-plans/${agentIdEncrypted}/${this.groupId}/${platformId}`
        window.open(benefitStoreLink, '_blank');
        this.$bvModal.hide("viewPlanModal");
      }
    },
    checkGroupValidation () {
      if (this.groupId == '') {
        this.groupInvaild = true
      } else {
        this.groupInvaild = false
      }
    }
  }
}
</script>

<style scoped>
.select-drop-down {
  padding: 6px;
  width: 100%;
  border: 1px solid #ced4da;
  background-color: #fff;
  border-radius: 5px;
}
.select-drop-down:focus {
  border: 1px solid #ced4da;
}
.select-container {
  padding-bottom: 10px;
}
.btn-container {
  padding-top: 8px;
  width: 40%;
  @media (max-width: 992px) {
    width: 100%;
  }
}
.form-invalid {
  border: 1px solid #ff0000 !important;
}
.form-invalid:focus {
  border: 1px solid #ff0000 !important;
  box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%);
}
.form-error {
  color: #ff0000;
}
input:focus, textarea:focus, select:focus{
  outline: none;
}
.required:after {
  content:" *";
  color: #ff0000;
}
</style>
