<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-4">
          <div class="overallInfo-blockWrapper benefitstore-dash">
            <div class="benefitstore-image">
              <img src="../../assets/images/dashbenefit-store.svg" alt="" />
            </div>
            <div class="benefitstore-content">
              <h2>The Benefit Store</h2>
              <button
                @click="redirectToBenefitStore()"
                class="dashboard-button"
              >
                Add or Enroll
              </button>
              <button class="dashboard-button"  @click="openModal('viewPlanModal')" v-if="!hideViewPlanBtn">View Plans</button>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-4">
          <h5 class="title-section">Manage Business</h5>
          <div class="overallInfo-blockWrapper rep-dash p-3">
            <div class="manage-btn ">
              <button  @click="routeToLink('/view-members')">Members</button>
              <button @click="routeToLink('/view-employers')">Employer and Groups</button>
              <button  @click="routeToLink('/view-downline-reps')" class="mb-0">Downline Reps</button>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-4">
          <dash-messages />
        </div>
        <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-4" v-if="uplineInfo.sso_user_id != 35311">
          <h5  class="title-section">
            Commission
          </h5>

          <div class="overallInfo-blockWrapper rep-dash p-3" v-if="!isDataLoaded">
            <no-content :text="messageText" />
          </div>
          <div class="overallInfo-blockWrapper rep-dash p-3" v-else>

             <p class="mb-2">Current Month : <b>{{currentMonth}}</b>

            </p>
             <p class="mb-2">Current Commission Amount: <b id="commission_current_month">${{currentMonthCommission}}</b>
              <eye-button
													id="commission_current_month"
                          :nonInput="1"
                          :value="'$'+currentMonthCommission"
                          initialValue="XXXX.XX"
													class="toggle-eye-icon"
													type="text"
												></eye-button>
            </p>
             <p class="mb-2">Year to Date Commission Amount: <b id="commission_year_to_date">${{totalCommission}}</b>
                          <eye-button
													id="commission_year_to_date"
                          :nonInput="1"
                          :value="'$'+totalCommission"
                          initialValue="XXXX.XX"
													class="toggle-eye-icon"
													type="text"
												></eye-button>
                      </p>
            <div class="comm-btn-wrap">
              <button class="btn-dash comm-btn mb-0 mb-2" @click="routeToLink('/commissions')">Commission Statements </button>
              <template v-if="uplineInfo.sso_user_id != 35311">
                <button class="btn-dash comm-btn mb-0 mt-1" @click="routeToLink('/commission-config')">Commission Schedule </button>
              </template>
            </div>
          </div>

          <!-- <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
          <benefit-store />
        </div> -->
          <!-- <div class="col-xxl-3 col-xl-3 col-lg-3 mb-4">
          <rep-info />

        </div> -->
        </div>
      </div>
      <div class="row">
        <div
          class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 vue-custom-scrollbar"
        >
          <pending-items />
        </div>
      </div>
      <div class="row">
        <personalized-sites />
        <dash-notification />
        <dash-knowledge />
        <resources />
        <!-- <business-overview /> -->
      </div>
      <div>
        <view-plan-modal :platformInfo.sync="platformInfo"  :repInfo="repInfo"/>
      </div>
      <!-- <overall-info /> -->
      <!-- <commission-projected /> -->
      <!-- <div class="row">
        <dash-messages />

      </div> -->
      <!-- <div class="row">
        <resources />
      </div> -->
      <!-- <RepInfo v-if="showValidationPopUp"/> -->
    </div>
  </div>
</template>

<script>
import Helper from "@/resource/Helper";
// import RepInfo from "@/components/AccountInfo/RepInfo.vue"
import Resources from "@/views/Dashboard/partials/Resources.vue";
import PendingItems from "@/views/Dashboard/partials/PendingItems.vue";
import DashMessages from "@/views/Dashboard/partials/DashMessages.vue";
// import BenefitStore from "@/views/Dashboard/partials/BenefitStore.vue";
import DashKnowledge from "@/views/Dashboard/partials/DashKnowledge.vue";
// import BusinessOverview from "@/views/Dashboard/partials/BusinessOverview.vue";
import PersonalizedSites from "@/views/Dashboard/partials/PersonalizedSites.vue";
import DashNotification from "@/views/Dashboard/partials/DashNotification.vue";
// import repInfo from '@/components/dashboard/repInfo.vue'
import Api from "@/resource/Api";
import ViewPlanModal from "./model/ViewPlansModal.vue";
import endpoint from "@/resource/EndPoints/endpoints"

export default {
  name: "Dashboard",
  components: {
    // RepInfo,
    // BenefitStore,
    // BusinessOverview,
    // DashMessages,
    DashMessages,
    DashKnowledge,
    PersonalizedSites,
    PendingItems,
    Resources,
    DashNotification,
    ViewPlanModal
    // repInfo
  },
  data: () => ({
    currentMonth: '',
    currentMonthCommission: '',
    totalCommission: '',
    messageText: '',
    isDataLoaded: false,
    platformInfo: [],
    hideViewPlanBtn: false
  }),
  created() {
    Helper.dashboardGetters();
    this.$store.dispatch("getRepInfo");
    this.getCommissionInfo();
  },
  computed: {
    showValidationPopUp() {
      return !this.$store.getters.rep.is_data_confirmed;
    },
    repInfo () {
      return this.$store.getters.rep
    },
    hideViewPlanBtn () {
      return this.$store.getters.isViewPlatformList
    },
    uplineInfo(){
      return this.$store.getters.upLineInfo
    }
  },

   
  methods: {
    redirectToBenefitStore() {
      let benefitStoreLink;
      benefitStoreLink = Helper.benefitStoreLink();
      window.open(benefitStoreLink, "_blank");
    },
    openModal (modalId) {
      if (this.repInfo.platformList.is_multiple) {
        this.platformInfo = this.repInfo.platformList.data;
        this.$bvModal.show(modalId);
       } else {
        let platformInfo = this.repInfo.platformList.data[0]
        let agentIdEncrypted = btoa(this.repInfo.id)
        let benefitStoreLink = `${endpoint.BENEFIT_STORE_URL}/view-plans/${agentIdEncrypted}/${platformInfo.group_id}/${platformInfo.group_id}`
        window.open(benefitStoreLink, '_blank');
        }
    },
    getCommissionInfo() {
      let url;
      let app = this
      app.isDataLoaded = false
      this.messageText = "Loading commission...";
      url = `v2/commission/overview`;
      Api.get(url)
          .then((res) => {
            app.isDataLoaded = true
            let result = res.data.data
            app.currentMonth = result.current_month
            app.currentMonthCommission = result.current_month_commission
            app.totalCommission = result.total_commission
          })
          .catch((err) => {
            app.isDataLoaded = true
            console.log(err)
          });
    },
    routeToLink (url) {
      let app = this
      app.$router.push(`${url}`)
    },
  },
};
</script>
<style src="@/assets/css/repdashboard.css"></style>
<style scoped>
.toggle-eye-icon {
  /* float:right; */
   margin-left: 2% !important;
}
</style>
