<template>
  <b-modal
    id="resourceReportModal"
    class="resource-modal"
    centered
    size="lg"
    hide-footer
  >
    <template #modal-header>
      <div class="resources-header">
        <h4>Report</h4>
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid ref="resourceReportContainer">
      <div class="resources-modal-content">
        <ul class="rep-report">
          <li v-if="isVipUser">
            <div class="report-icon-block" v-html="imageBlock"></div>
            <div class="pdf-links">
              All Member Contact Report
              <date-picker
                class="all-contact-datepicker"
                placeholder="Select Month"
                type="month"
                value-type="format"
                format="YYYY-MM"
                v-model="selectedAllReportDate"
                :default-value="new Date()"
                :disabled-date="disabledAfterToday"
              />
            </div>
            <resource-report-download-button
              v-if="selectedAllReportDate"
              @onClicked="
                downloadReport();
                memberContactReport = 'ALL';
              "
              :isDownload="memberContactReport == 'ALL'"
            />
          </li>
          <li>
            <div class="report-icon-block" v-html="imageBlock"></div>
            <div class="pdf-links">
              Member Contact Report (
              <span v-if="!isVipUser">
                <a href="javascript:void(0);" @click="downloadReport()">
                  All
                </a>
                |
              </span>
              <a href="javascript:void(0);" @click="downloadReport('ACTIVE')">
                Active
              </a>
              |
              <a href="javascript:void(0);" @click="downloadReport('TERMED')">
                Termed
              </a>
              )
            </div>
          </li>
          <li>
            <div class="report-icon-block" v-html="imageBlock"></div>
            <div class="pdf-links">
              Active Member Report Primary Only
              <br />
              <multiselect
                v-model="date1"
                placeholder="Select Effective Date"
                :options="effectiveDates"
              />
            </div>
            <resource-report-download-button
              @onClicked="downloadContactReport('MEMBER_PRIMARY')"
              :isDownload="
                memberContactReport == 'MEMBER_PRIMARY' ? true : false
              "
            />
          </li>
          <li>
            <div class="report-icon-block" v-html="imageBlock"></div>
            <div class="pdf-links">
              Active Member Report with Dependents 
              <br />
              <div class="dependent-date">
                <date-picker
                  v-if="isVipUser"
                  class="all-contact-datepicker"
                  placeholder="Select Month"
                  type="month"
                  value-type="format"
                  format="YYYY-MM"
                  v-model="selectedDependentDate"
                  :default-value="new Date()"
                  :disabled-date="disabledAfterToday"
                />
                <multiselect
                  v-if="!isVipUser"
                  v-model="date2"
                  placeholder="Select Effective Date"
                  :options="effectiveDates"
                />
              </div>
            </div>
            <template v-if="isVipUser">
              <resource-report-download-button
                v-if="selectedDependentDate"
                @onClicked="downloadContactReport('MEMBER_DEPENDENT')"
                :isDownload="
                  memberContactReport == 'MEMBER_DEPENDENT' ? true : false
                "
              />
            </template>
            <template v-else>
              <resource-report-download-button
                @onClicked="downloadContactReport('MEMBER_DEPENDENT')"
                :isDownload="
                  memberContactReport == 'MEMBER_DEPENDENT' ? true : false
                "
              />
            </template>
          </li>
        </ul>
        <div>
          <header class="modal-header">
            <div class="resources-header">
              <h4 class="rep">Rep Related Reports</h4>
            </div>
              <i
              class="accordion-icon"
              :class="accordion ? 'fa fa-angle-up' : 'collapsed fa fa-angle-down'"
              :aria-expanded="accordion ? 'true' : 'false'"
              aria-controls="collapse-4"
              @click="accordion = !accordion"
            />
          </header>

          <b-collapse
            id="accordion-1"
            v-model="accordion"
            accordion="my-accordion"
            role="tabpanel"
          >
            <ul class="rep-report-list">
              <resource-rep-report-list-block v-if="isVipUser"
                title="Reps That Haved Logged Into The Dashboard."
                @onClicked="downloadRepReport('LOGGED_IN_REPS')"
                :isDownload="repReportType == 'LOGGED_IN_REPS' ? true : false"
              />
              <resource-rep-report-list-block v-if="isVipUser"
                title="Reps With One/More Online Enrollments."
                @onClicked="downloadRepReport('REP_ONLINE_ENROLLMENT ')"
                :isDownload="
                  repReportType == 'REP_ONLINE_ENROLLMENT ' ? true : false
                "
              />

              <resource-rep-report-list-block v-if="isVipUser"
                title="Reps With Online Enrollments But Not Logged Into The Dashboard."
                @onClicked="
                  downloadRepReport('REP_ONLINE_ENROLLMENT_NOT_LOGGED_IN ')
                "
                :isDownload="
                  repReportType == 'REP_ONLINE_ENROLLMENT_NOT_LOGGED_IN '
                    ? true
                    : false
                "
              />
              <resource-rep-report-list-block v-if="isVipUser"
                title="Reps With Online Enrollments And Logged Into The Dashboard."
                @onClicked="
                  downloadRepReport('REP_ONLINE_ENROLLMENT_LOGGED_IN ')
                "
                :isDownload="
                  repReportType == 'REP_ONLINE_ENROLLMENT_LOGGED_IN '
                    ? true
                    : false
                "
              />

              <resource-rep-report-list-block v-if="isVipUser"
                title="Reps With Paper Enrollments Only."
                @onClicked="downloadRepReport('REP_PAPER_ENROLLMENT ')"
                :isDownload="
                  repReportType == 'REP_PAPER_ENROLLMENT ' ? true : false
                "
              />
              <resource-rep-report-list-block v-if="isVipUser"
                title="Reps With Paper Enrollments But Not Logged Into The Dashboard."
                @onClicked="
                  downloadRepReport('REP_PAPER_ENROLLMENT_NOT_LOGGED_IN ')
                "
                :isDownload="
                  repReportType == 'REP_PAPER_ENROLLMENT_NOT_LOGGED_IN '
                    ? true
                    : false
                "
              />
              <resource-rep-report-list-block
                title="Rep Contact Report."
                @onClicked="downloadRepReport('REP_CONTACT_REPORT')"
                :isDownload="
                  repReportType == 'REP_CONTACT_REPORT' ? true : false
                "
              />
              <resource-rep-report-list-block
                title="Rep Direct Downline Report."
                @onClicked="downloadRepReport('REP_DIRECT_DOWNLINE')"
                :isDownload="
                  repReportType == 'REP_DIRECT_DOWNLINE' ? true : false
                "
              />
              <resource-rep-report-list-block
                title="Rep Non Direct Downline Report."
                @onClicked="downloadRepReport('REP_NON_DIRECT_DOWNLINE')"
                :isDownload="
                  repReportType == 'REP_NON_DIRECT_DOWNLINE' ? true : false
                "
              />
            </ul>
          </b-collapse>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
import ResourceRepReportListBlock from "./ResourceRepReportListBlock";
import ResourceReportDownloadButton from "./ResourceReportDownloadButtton.vue";
export default {
  name: "resourceReportModal",
  props: {
    effectiveDates: {
      required: true,
    },
  },
  components: {
    ResourceRepReportListBlock,
    ResourceReportDownloadButton,
  },
  data: () => ({
    date1: "",
    date2: "",
    repReportScroll: {
      vuescroll: {
        mode: "native",
        wheelScrollDuration: "700",
      },
      scrollPanel: {
        maxHeight: 400,
      },
      bar: {
        background: "#ddd",
      },
    },
    isDownload: false,
    repReportType: null,
    memberContactReport: null,
    selectedAllReportDate: "",
    selectedDependentDate: "",
    accordion: true,
  }),
  computed: {
    ...mapGetters({ isVipUser: "vipUser" }),
    imageBlock() {
      let imageIcon = require(`@/assets/images/report.svg`);
      return `<img src="${imageIcon}" alt="" class="report-icon" />`;
    },
  },
  methods: {
    closeModal() {
      this.selectedAllReportDate = "";
      this.selectedDependentDate = "";
      this.accordion = false
      this.$bvModal.hide("resourceReportModal");
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    downloadContactReport(type = "") {
      this.memberContactReport = type;
      let url, loader;
      loader = this.$loading.show({
        container: this.$refs.resourceReportContainer,
      });
      if (type == "MEMBER_PRIMARY") {
        url = `v2/member-report/contact-report?status=ACTIVE`;
        url = this.date1 ? url + `&effective_date=${this.date1}` : url;
      } else if (type == "MEMBER_DEPENDENT") {
        let date = this.selectedDependentDate
          ? `&selected_date=${this.selectedDependentDate}`
          : "";
        url = `v2/member-report/contact-report?with_dependent=1${date}`;
        url = this.date2 ? url + `&effective_date=${this.date2}` : url;
      }
      Api.get(url)
        .then((res) => {
          let fetchUrl = res.data.data.file_url;
          if (fetchUrl) {
            this.date1 = "";
            this.date2 = "";
            this.selectedDependentDate = "";
            window.open(fetchUrl, "_blank");
          }
        })
        .catch(() => {
          this.$notify("Failed to download contact report", "error");
        })
        .finally(() => {
          loader.hide();
          this.memberContactReport = null;
        });
    },
    downloadRepReport(type = "") {
      this.repReportType = type;
      let url = `v2/rep-related-report/report?type=${type}`;
      let loader;
      const reportTypes = ["REP_CONTACT_REPORT", "REP_DIRECT_DOWNLINE", "REP_NON_DIRECT_DOWNLINE"];
      if (reportTypes.includes(type)) {
        loader = this.$loading.show({
          container: this.$refs.resourceReportContainer,
        });
      }
      Api.get(url)
        .then((res) => {
          let fetchUrl = res.data.data.file_url;
          console.log(fetchUrl)
          if (fetchUrl) {
            window.open(fetchUrl, "_blank");
          }
        })
        .catch(() => {
          this.$notify("Failed to download report", "error");
        })
        .finally(() => {
          this.repReportType = null;
          loader.hide();
        });
    },
    async downloadReport(status = "") {
      console.log('downloading')
      let url = "v2/member-report/contact-report";
      if (status) {
        url = url + `?status=${status}`;
      } else if (status == "" && this.selectedAllReportDate) {
        url = url + `?selected_date=${this.selectedAllReportDate}`;
      }
      let loader = this.$loading.show({
        container: this.$refs.resourceReportContainer,
      });
      await Api.get(url)
        .then((res) => {
          let fetchUrl = res.data.data.file_url;
          console.log(fetchUrl)
          if (fetchUrl) {
            window.open(fetchUrl, "_blank");
          }
          this.selectedAllReportDate = "";
        })
        .catch(() => {
          this.$notify("Failed to download report", "error");
        })
        .finally(() => {
          loader.hide();
           this.memberContactReport = null;
        });
    },
  },
};
</script>
<style >
.contract-button {
  color: #0d6efd;
}
.report-icon {
  width: 70%;
}
.report-icon-block {
  display: inline-block;
  vertical-align: top;
  width: 40px;
}
.report-download-btn {
  float: right;
  color: #4db7e9;
  font-size: 18px;
  margin-right: 10px;
}

.resources-header {
  padding-left: 0;
}

.rep-report-list {
  padding-bottom: 0;
  margin-top: 20px !important;
}
.rep-report li:last-child,
.rep-report-list li:last-child {
  border: none;
}

/* .rep-report  li,
.rep-report-list  li {
  margin-bottom: 12px !important;
} */

.multiselect {
  width: 200px !important;
  margin-left: 20px !important;
}

.multiselect__element {
  color: black;
}

.all-contact-datepicker {
  padding-left: 23px;
}

.dependent-date {
  display: flex;
  margin-top: 10px;
}

.all-contact-datepicker > .mx-input-wrapper > .mx-input {
  height: 42px;
  padding-left: 10px;
  border: 1px solid #e8e8e8;
  box-shadow: inherit;
}
.resources-modal-content ul li {
  margin-bottom: 0;
  margin-top: 18px;
}
@media (max-width: 990px) {
  .all-contact-datepicker {
    display: block;
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }
  .pdf-links .multiselect {
    margin: 10px 0 0 0 !important;
    width: 100% !important;
  }
  .dependent-date {
    display: block;
  }
  .resources-modal-content .pdf-links {
    width: 80%;
  }
}
@media (max-width: 410px) {
  .resources-modal-content .pdf-links {
    width: 70%;
  }
}

.accordion-icon{
  cursor: pointer;
  color: #4db7e9;
}

</style>