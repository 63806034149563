var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "resourcesVideoLibraryModal",
      "centered": "",
      "size": "lg",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "resources-header",
          attrs: {
            "id": "resourceHeader"
          }
        }, [_c('h4', [_vm._v("Video Library")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": function click($event) {
              return _vm.$bvModal.hide('resourcesVideoLibraryModal');
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "resources-modal-content video-library-content",
    attrs: {
      "id": "resourceModal"
    }
  }, [_c('resource-video-player', {
    attrs: {
      "video": _vm.playVideo
    },
    on: {
      "update:video": function updateVideo($event) {
        _vm.playVideo = $event;
      }
    }
  }), _vm.rows.length ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.rows, function (row, index) {
    return _c('div', {
      key: index,
      staticClass: "col-sm-4"
    }, [_c('h6', [_vm._v(_vm._s(row.name))]), _c('ul', [row.children && row.children.length ? _c('div', _vm._l(row.children, function (subCategory, index) {
      return _c('li', {
        key: index,
        class: !subCategory.videos.length ? 'd-none' : ''
      }, [_c('span', [_vm._v(_vm._s(subCategory.name) + ":")]), _c('br'), _vm._l(subCategory.videos, function (subCategoryVideo, index) {
        return _c('a', {
          key: index,
          attrs: {
            "href": "javascript:void(0)"
          },
          on: {
            "click": function click($event) {
              return _vm.selectVideo(subCategoryVideo);
            }
          }
        }, [_vm._v(_vm._s(subCategoryVideo.title))]);
      })], 2);
    }), 0) : _vm._e(), row.videos.length ? _c('div', _vm._l(row.videos, function (video, index) {
      return _c('li', {
        key: index
      }, [_c('a', {
        attrs: {
          "href": "javascript:void(0)"
        },
        on: {
          "click": function click($event) {
            return _vm.selectVideo(video);
          }
        }
      }, [_vm._v(_vm._s(video.title))])]);
    }), 0) : _vm._e()])]);
  }), 0) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }