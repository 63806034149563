var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.video ? _c('div', {
    attrs: {
      "controls": ""
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "close-btn play-video-close-btn",
      "title": "Close Video"
    },
    on: {
      "click": _vm.closeVideo
    }
  }, [_c('i', {
    staticClass: "fas fa-times"
  })]), _c('b-embed', {
    staticClass: "play-video",
    attrs: {
      "type": "video",
      "aspect": "1by1",
      "controls": "",
      "poster": _vm.video.thumbnail_url
    }
  }, [_c('source', {
    attrs: {
      "src": _vm.video.video_url
    }
  })])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }