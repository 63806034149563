var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    staticClass: "resource-modal",
    attrs: {
      "id": _vm.modalId,
      "centered": "",
      "size": "md",
      "hide-footer": ""
    },
    on: {
      "shown": _vm.getData
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "resources-header"
        }, [_c('h4', [_vm._v(_vm._s(_vm.modalTitle))]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "resources-modal-content resource-common-modal"
  }, [_c('vue-scroll', {
    attrs: {
      "ops": _vm.dataScroll
    }
  }, [_c('ul', _vm._l(_vm.contracts, function (b, index) {
    return _c('li', {
      key: index,
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "dashres-icon"
    }, [_c('img', {
      attrs: {
        "src": _vm.pdfIcon,
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "pdf-links d-flex justify-content-between",
      staticStyle: {
        "width": "80%"
      }
    }, [_c('span', [_vm._v(_vm._s(b.contract_type.charAt(0).toUpperCase() + b.contract_type.slice(1)))]), b.screenshots ? [_c('custom-button', {
      attrs: {
        "title": "Download Document",
        "iconClass": "fas fa-download"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.downloadHandler(b.screenshots);
        }
      }
    })] : _vm._e()], 2)]);
  }), 0)])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }