var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-md-6 col-sm-6 mb-5"
  }, [_c('div', {
    staticClass: "dashres-wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "dashres-card"
  }, [_c('ul', [_c('li', [_c('dashboard-list-block', {
    attrs: {
      "imageName": "report.svg",
      "title": "Report"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.$bvModal.show('resourceReportModal');
      }
    }
  })], 1), _c('li', [_c('dashboard-list-block', {
    attrs: {
      "imageName": "contract.svg",
      "title": "Contracts"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.$bvModal.show('downloadContractModal');
      }
    }
  })], 1), _c('li', [_c('dashboard-list-block', {
    attrs: {
      "imageName": "brochure.svg",
      "title": "Brochure/ Kits"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.showModal('resourcesBrochureModal');
      }
    }
  })], 1), _c('li', [_c('dashboard-list-block', {
    attrs: {
      "imageName": "information.svg",
      "title": "Information/ How To's"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.showModal('resourcesInfoModal');
      }
    }
  })], 1), _c('li', [_c('dashboard-list-block', {
    attrs: {
      "imageName": "video.svg",
      "title": "Video Library"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.$bvModal.show('resourcesVideoLibraryModal');
      }
    }
  })], 1)])]), _c('resource-video-modal', {
    attrs: {
      "rows": _vm.videoCategories
    }
  }), _c('resource-common-modal', {
    attrs: {
      "modalId": "resourcesBrochureModal",
      "modalTitle": "BROCHURES/KITS",
      "modalData": _vm.brochures
    }
  }), _c('download-contract-modal', {
    attrs: {
      "modalId": "downloadContractModal",
      "modalTitle": "Contracts"
    }
  }), _c('resource-common-modal', {
    attrs: {
      "modalId": "resourcesInfoModal",
      "modalTitle": "INFORMATION/HOW TO'S",
      "modalData": _vm.info
    }
  }), _c('resource-report-modal', {
    attrs: {
      "effectiveDates": _vm.effectiveDates
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashres-card-title"
  }, [_c('h2', [_vm._v("Resources")])]);
}]

export { render, staticRenderFns }