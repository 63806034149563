<template>
  <b-modal
    id="updatePersonalizedSite"
    centered
    size="md"
    no-close-on-backdrop
    hide-footer
    @shown="setData"
  >
    <template #modal-header>
      <div class="personalized-site-header text-center">
        <h4>Edit Landing Page Url</h4>
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times" />
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="personalized-site-modal-content">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form
            @submit.stop.prevent="handleSubmit(updatePersonalizedSite)"
            ref="updatePersonalizedSiteContainer"
          >
            <div class="form-group row">
              <input-text
                :rules="{ required: true, alpha_num: true }"
                labelFor="web_access_code"
                labelName="Landing Page Url"
                :vmodel.sync="web_access_code"
                formGroupClass="col-xxl-12"
              />
            </div>
            <div
              class="form-group text-center col-xxl-12 personalized-site-submit"
            >
              <b-button class="univ-btn" type="submit" :disabled="isUpdate">
                <template v-if="isUpdate"> Updating ... </template>
                <template v-else> Update </template>
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "PersonalizedSiteEdit",
  props: {
    webAccessCode: {
      type: String,
      required: true,
    },
    updateUrl: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    web_access_code: "",
    isUpdate: false,
  }),
  methods: {
    closeModal() {
      this.web_access_code = "";
      this.$bvModal.hide("updatePersonalizedSite");
    },
    updatePersonalizedSite() {
      this.isUpdate = true;
      const url = this.updateUrl;
      let loader = this.$loading.show({
        container: this.$refs.updatePersonalizedSiteContainer,
      });
      if (url) {
        Api.put(url, { web_access_code: this.web_access_code })
          .then((res) => {
            this.$notify(res.data.message);
            this.closeModal();
            this.$emit("onSuccess");
          })
          .catch((err) => {
            this.$notify(err.response.data.message, "error");
            this.$refs.observer.reset();
          })
          .finally(() => {
            this.isUpdate = false;
            setTimeout(() => {
              loader.hide();
            }, 2000);
          });
      } else {
        this.$notify("Something Went Wrong", "error");
      }
    },
    setData() {
      this.web_access_code = this.webAccessCode;
    },
  },
};
</script>
