<template>
  <div class="col-xxl-3 col-xl-3 col-md-6 col-sm-12 mb-5">
    <div class="dashknowledge-wrap">
      <div class="dashknowledge-card-title">
        <h2>Knowledge Center</h2>
        <router-link
          :to="{
            name: 'KnowledgeCenter',
          }"
        >
          View All
        </router-link>
      </div>
      <div class="dashknowledge-card">
        <vue-scroll :ops="knwScroll">
          <ul v-if="knowledges.length > 0">
            <li
              v-for="k in knowledges"
              :key="k.id"
              @click="showKnowledgeData(k)"
              class="knowledge-list"
            >
              <knowledge-center-list :list="k" />
            </li>
          </ul>
          <ul v-else>
            <li>
              <no-content text="No knowledge center data found." />
            </li>
          </ul>
        </vue-scroll>
      </div>
    </div>
    <knowledge-center-view :knowledge="knowledge" @onCloseModal="knowledge = null"/>
  </div>
</template>
<script>
import axios from "axios";
import KnowledgeCenterList from "@/views/KnowledgeCenter/partials/KnowledgeCenterList";
import KnowledgeCenterView from "@/views/KnowledgeCenter/partials/KnowledgeCenterView";
export default {
  name: "DashKnowledge",
  components: { KnowledgeCenterList, KnowledgeCenterView },
  data: () => ({
    knwScroll: {
      vuescroll: {
        mode: "native",
        wheelScrollDuration: "700",
      },
      scrollPanel: {
        maxHeight: 400,
        speed: 2000,
      },
      bar: {
        background: "#ddd",
      },
    },
    knowledges: [],
    knowledge: "",
  }),
  methods: {
    showKnowledgeData(row) {
      this.knowledge = row;
      this.$bvModal.show("viewKnowledge");
    },
    getKnowledges() {
      //let url = this.$endpoint.KNOWLEDGE_BASE_API_URL + `?page_size=10`;
      axios
        .get('v2/knowledge-feed/merged-feed?user_related_feed=1')
        .then((res) => {
          this.knowledges = res.data.data.slice(0,10);
        })
        .catch(() => {
          this.knowledges = [];
        });
    },
  },
  created() {
    this.getKnowledges();
  },
};
</script>
<style scoped>
.knowledge-list {
  cursor: pointer;
}
</style>