var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "report-download-btn",
    attrs: {
      "href": "javascript:void(0)",
      "title": "Download"
    },
    on: {
      "click": _vm.onClickHandler
    }
  }, [_c('i', {
    class: _vm.isDownload ? 'fa fa-circle-o-notch fa-spin' : 'fas fa-download'
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }