var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "viewNotification",
      "centered": "",
      "size": "md",
      "modal-class": "view-notification-modal",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "addKnowledge-header view-notification-modal"
        }, [_c('h4', [_vm._v("Activity Detail")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('notification-content', {
    attrs: {
      "content": _vm.notification
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }