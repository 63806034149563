var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-md-6 col-sm-12 mb-5"
  }, [_c('div', {
    staticClass: "dashknowledge-wrap"
  }, [_c('div', {
    staticClass: "dashknowledge-card-title"
  }, [_c('h2', [_vm._v("Knowledge Center")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'KnowledgeCenter'
      }
    }
  }, [_vm._v(" View All ")])], 1), _c('div', {
    staticClass: "dashknowledge-card"
  }, [_c('vue-scroll', {
    attrs: {
      "ops": _vm.knwScroll
    }
  }, [_vm.knowledges.length > 0 ? _c('ul', _vm._l(_vm.knowledges, function (k) {
    return _c('li', {
      key: k.id,
      staticClass: "knowledge-list",
      on: {
        "click": function click($event) {
          return _vm.showKnowledgeData(k);
        }
      }
    }, [_c('knowledge-center-list', {
      attrs: {
        "list": k
      }
    })], 1);
  }), 0) : _c('ul', [_c('li', [_c('no-content', {
    attrs: {
      "text": "No knowledge center data found."
    }
  })], 1)])])], 1)]), _c('knowledge-center-view', {
    attrs: {
      "knowledge": _vm.knowledge
    },
    on: {
      "onCloseModal": function onCloseModal($event) {
        _vm.knowledge = null;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }