var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-md-6 col-sm-6 mb-4"
  }, [_c('div', {
    staticClass: "dashpersonalized-wrap"
  }, [_c('div', {
    staticClass: "dashpersonalized-card-title"
  }, [_c('h2', [_vm._v("Personalized Sites ")]), _c('custom-button', {
    attrs: {
      "iconClass": "fas fa-edit"
    },
    on: {
      "onClicked": _vm.editSite
    }
  })], 1), _c('div', {
    staticClass: "dashpersonalized-card dashpersonalized-body"
  }, [_c('vue-scroll', {
    attrs: {
      "ops": _vm.prsnlScroll
    }
  }, [_c('ul', _vm._l(_vm.sites, function (site, index) {
    return _c('li', {
      key: index,
      class: _vm.activeList == index ? 'active' : '',
      on: {
        "click": function click($event) {
          _vm.activeList = index;
        }
      }
    }, [_c('div', {
      staticClass: "dashprsnl-logo"
    }, [_c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": site.image,
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "dashprsnl-content"
    }, [_c('h3', [_vm._v(_vm._s(site.name))]), _c('span', {
      staticClass: "url-site"
    }, [_vm._v(" " + _vm._s(site.domain) + " ")])]), _c('div', {
      staticClass: "click-toggler"
    }, [_c('a', {
      staticClass: "visit-portal",
      attrs: {
        "href": site.domain,
        "target": "_blank"
      }
    }, [_vm._v(" Visit Portal ")]), _c('a', {
      staticClass: "send-email",
      on: {
        "click": function click($event) {
          return _vm.sendPersonalizedEmail(site.domain);
        }
      }
    }, [_c('span', [_vm._v(" Send Email ")])]), _c('a', {
      staticClass: "copy-link"
    }, [_c('span', {
      staticClass: "code",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.copyLink(site.domain);
        }
      }
    }, [_vm._v(" Copy Link ")])]), _c('a', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.QRModal",
        modifiers: {
          "QRModal": true
        }
      }],
      staticClass: "copy-link"
    }, [_c('span', {
      staticClass: "code",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.downloadQR(site.domain, site.name);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-qrcode"
    }), _vm._v(" QR Code ")])])])]);
  }), 0)])], 1), _c('personalized-site-edit', {
    attrs: {
      "web-access-code": _vm.webAccessCode,
      "updateUrl": _vm.updateLandingPageUrl
    },
    on: {
      "update:webAccessCode": function updateWebAccessCode($event) {
        _vm.webAccessCode = $event;
      },
      "update:web-access-code": function updateWebAccessCode($event) {
        _vm.webAccessCode = $event;
      },
      "onSuccess": _vm.getSites
    }
  }), _c('personalized-site-send-email-modal', {
    attrs: {
      "selected-link": _vm.selectedLink,
      "sendQR": _vm.sendQR
    },
    on: {
      "onCloseModal": function onCloseModal($event) {
        _vm.selectedLink = '';
      }
    }
  })], 1), _c('b-modal', {
    attrs: {
      "id": "QRModal",
      "centered": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "view-clients-modal-content"
  }, [_c('div', {
    staticClass: "repQR"
  }, [_c('img', {
    attrs: {
      "src": _vm.QRUrl,
      "alt": ""
    }
  }), _c('h4', [_vm._v(_vm._s(_vm.domainName))]), _c('p', [_vm._v(_vm._s(_vm.domainURL))])]), _c('div', {
    staticClass: "repInformation"
  }, [_c('div', [_c('h3', [_vm._v(_vm._s(_vm.$store.getters.rep.name))]), _c('span', {
    staticClass: "repCode"
  }, [_vm._v(_vm._s(_vm.$store.getters.rep.code))])]), _c('div', {
    staticClass: "downloadButton",
    staticStyle: {
      "margin-right": "-15%"
    }
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.sendPersonalizedEmail(_vm.domainURL, 1);
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-share-alt"
  }), _vm._v(" Share")])]), _c('div', {
    staticClass: "downloadButton"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.downloadFile();
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-download"
  }), _vm._v(" Download")])])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }