
<template>
  <div class="message-list-content">
    <template v-if="type == 'receive'">
      <div class="msg-userIcon">
        <img :src="image" alt="" />
      </div>
    </template>
    <template v-else>
      <div class="msg-userIcon send-msg-icon">
        <img src="@/assets/images/shortmessage.svg" />
      </div>
    </template>
    <div class="msgShort-detail">
      <span v-if="type == 'receive'">
        {{ row.sender_details.name }} - {{ row.from }}
      </span>
      <h3>{{ row.subject | truncate(50) }}</h3>
      <p :inner-html.prop="strippedHtmlMessage | truncate(100)"></p>
      <p class="message-list-date">
        <i class="far fa-calendar-alt"></i>
        {{
          row.date_created | date_format("MM/DD/YYYY HH:mm:ss", "MM/DD/YYYY")
        }}
      </p>
    </div>
  </div>
</template>
<script>
import dummyImage from "@/assets/images/user.svg";
export default {
  name: "MessageListContent",
  props: {
    row: {
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dummyImage,
  }),
  computed: {
    image() {
      return this.row.sender_details && this.row.sender_details.image
        ? this.row.sender_details.image
        : dummyImage;
    },
    strippedHtmlSubject() {
      let regex = /(<([^>]+)>)/gi;
      return this.row.subject.replace(regex, "");
    },
    strippedHtmlMessage() {
      let regex = /(<([^>]+)>)/gi;
      return this.row.message.replace(regex, "");
    },
  },
};
</script>
<style>
.message-list-content {
  display: flex;
}
.msg-userIcon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 15px;
}
.msg-userIcon img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.msgShort-detail {
  width: 88%;
}
.msgShort-detail span {
  font-size: 14px;
  font-weight: 600;
  color: #03c3ec;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
}
.msgShort-detail h3 {
  font-size: 14px;
  font-weight: 700;
  color: #2c3f58;
}
.msgShort-detail p {
  font-size: 14px;
  color: #778699;
  margin-bottom: 0;
}
.message-list-date {
  font-size: 12px !important;
  color: #ccc !important;
}

</style>