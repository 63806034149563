var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-4"
  }, [_c('div', {
    staticClass: "overallInfo-blockWrapper benefitstore-dash"
  }, [_vm._m(0), _c('div', {
    staticClass: "benefitstore-content"
  }, [_c('h2', [_vm._v("The Benefit Store")]), _c('button', {
    staticClass: "dashboard-button",
    on: {
      "click": function click($event) {
        return _vm.redirectToBenefitStore();
      }
    }
  }, [_vm._v(" Add or Enroll ")]), !_vm.hideViewPlanBtn ? _c('button', {
    staticClass: "dashboard-button",
    on: {
      "click": function click($event) {
        return _vm.openModal('viewPlanModal');
      }
    }
  }, [_vm._v("View Plans")]) : _vm._e()])])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-4"
  }, [_c('h5', {
    staticClass: "title-section"
  }, [_vm._v("Manage Business")]), _c('div', {
    staticClass: "overallInfo-blockWrapper rep-dash p-3"
  }, [_c('div', {
    staticClass: "manage-btn "
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.routeToLink('/view-members');
      }
    }
  }, [_vm._v("Members")]), _c('button', {
    on: {
      "click": function click($event) {
        return _vm.routeToLink('/view-employers');
      }
    }
  }, [_vm._v("Employer and Groups")]), _c('button', {
    staticClass: "mb-0",
    on: {
      "click": function click($event) {
        return _vm.routeToLink('/view-downline-reps');
      }
    }
  }, [_vm._v("Downline Reps")])])])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-4"
  }, [_c('dash-messages')], 1), _vm.uplineInfo.sso_user_id != 35311 ? _c('div', {
    staticClass: "col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-4"
  }, [_c('h5', {
    staticClass: "title-section"
  }, [_vm._v(" Commission ")]), !_vm.isDataLoaded ? _c('div', {
    staticClass: "overallInfo-blockWrapper rep-dash p-3"
  }, [_c('no-content', {
    attrs: {
      "text": _vm.messageText
    }
  })], 1) : _c('div', {
    staticClass: "overallInfo-blockWrapper rep-dash p-3"
  }, [_c('p', {
    staticClass: "mb-2"
  }, [_vm._v("Current Month : "), _c('b', [_vm._v(_vm._s(_vm.currentMonth))])]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v("Current Commission Amount: "), _c('b', {
    attrs: {
      "id": "commission_current_month"
    }
  }, [_vm._v("$" + _vm._s(_vm.currentMonthCommission))]), _c('eye-button', {
    staticClass: "toggle-eye-icon",
    attrs: {
      "id": "commission_current_month",
      "nonInput": 1,
      "value": '$' + _vm.currentMonthCommission,
      "initialValue": "XXXX.XX",
      "type": "text"
    }
  })], 1), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v("Year to Date Commission Amount: "), _c('b', {
    attrs: {
      "id": "commission_year_to_date"
    }
  }, [_vm._v("$" + _vm._s(_vm.totalCommission))]), _c('eye-button', {
    staticClass: "toggle-eye-icon",
    attrs: {
      "id": "commission_year_to_date",
      "nonInput": 1,
      "value": '$' + _vm.totalCommission,
      "initialValue": "XXXX.XX",
      "type": "text"
    }
  })], 1), _c('div', {
    staticClass: "comm-btn-wrap"
  }, [_c('button', {
    staticClass: "btn-dash comm-btn mb-0 mb-2",
    on: {
      "click": function click($event) {
        return _vm.routeToLink('/commissions');
      }
    }
  }, [_vm._v("Commission Statements ")]), _vm.uplineInfo.sso_user_id != 35311 ? [_c('button', {
    staticClass: "btn-dash comm-btn mb-0 mt-1",
    on: {
      "click": function click($event) {
        return _vm.routeToLink('/commission-config');
      }
    }
  }, [_vm._v("Commission Schedule ")])] : _vm._e()], 2)])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-12 col-xl-12 col-lg-12 col-md-12 vue-custom-scrollbar"
  }, [_c('pending-items')], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('personalized-sites'), _c('dash-notification'), _c('dash-knowledge'), _c('resources')], 1), _c('div', [_c('view-plan-modal', {
    attrs: {
      "platformInfo": _vm.platformInfo,
      "repInfo": _vm.repInfo
    },
    on: {
      "update:platformInfo": function updatePlatformInfo($event) {
        _vm.platformInfo = $event;
      },
      "update:platform-info": function updatePlatformInfo($event) {
        _vm.platformInfo = $event;
      }
    }
  })], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "benefitstore-image"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/dashbenefit-store.svg"),
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }