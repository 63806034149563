<template>
  <b-modal id="resourcesVideoLibraryModal" centered size="lg" hide-footer>
    <template #modal-header>
      <div class="resources-header" id="resourceHeader">
        <h4>Video Library</h4>
        <b-button
          variant="close-btn"
          @click="$bvModal.hide('resourcesVideoLibraryModal')"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div
        id="resourceModal"
        class="resources-modal-content video-library-content"
      >
        <resource-video-player :video.sync="playVideo" />
        <div class="row" v-if="rows.length">
          <div class="col-sm-4" v-for="(row, index) in rows" :key="index">
            <h6>{{ row.name }}</h6>
            <ul>
              <div v-if="row.children && row.children.length">
                <li
                  v-for="(subCategory, index) in row.children"
                  :key="index"
                  :class="!subCategory.videos.length ? 'd-none' : ''"
                >
                  <span>{{ subCategory.name }}:</span><br />
                  <a
                    v-for="(subCategoryVideo, index) in subCategory.videos"
                    :key="index"
                    href="javascript:void(0)"
                    @click="selectVideo(subCategoryVideo)"
                    >{{ subCategoryVideo.title }}</a
                  >
                </li>
              </div>
              <div v-if="row.videos.length">
                <li v-for="(video, index) in row.videos" :key="index">
                  <a href="javascript:void(0)" @click="selectVideo(video)">{{
                    video.title
                  }}</a>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import ResourceVideoPlayer from "./ResourceVideoPlayer.vue";
export default {
  components: { ResourceVideoPlayer },
  name: "ResourceVideoModal",
  props: {
    rows: {
      required: true,
      default: [],
    },
  },
  data: () => ({
    playVideo: null,
  }),
  methods: {
    selectVideo(video) {
      this.playVideo = null;
      /**
       * scrooling top to header
       */
      document.getElementById("resourceHeader").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      this.$nextTick(() => {
        this.playVideo = video;
      });
    },
  },
};
</script>
