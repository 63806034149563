var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clickHandler.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "dashres-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm.image,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "dashres-content"
  }, [_c('h3', [_vm._v(_vm._s(_vm.title))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }