<template>
  <div class="col-xxl-3 col-xl-3 col-md-6 col-sm-6 mb-5">
    <div class="dashres-wrap">
      <div class="dashres-card-title">
        <h2>Resources</h2>
      </div>
      <div class="dashres-card">
        <ul>
          <li>
            <dashboard-list-block
              imageName="report.svg"
              title="Report"
              @onClick="$bvModal.show('resourceReportModal')"
            />
          </li>
          <li>
            <dashboard-list-block
              imageName="contract.svg"
              title="Contracts"
              @onClick="$bvModal.show('downloadContractModal')"
            />
          </li>
         
          <li>
            <dashboard-list-block
              imageName="brochure.svg"
              title="Brochure/ Kits"
              @onClick="showModal('resourcesBrochureModal')"
            />
          </li>
          <li>
            <dashboard-list-block
              imageName="information.svg"
              title="Information/ How To's"
              @onClick="showModal('resourcesInfoModal')"
            />
          </li>
          <li>
            <dashboard-list-block
              imageName="video.svg"
              title="Video Library"
              @onClick="$bvModal.show('resourcesVideoLibraryModal')"
            />
          </li>
        </ul>
      </div>
      <resource-video-modal :rows="videoCategories"/>
      <resource-common-modal
        modalId="resourcesBrochureModal"
        modalTitle="BROCHURES/KITS"
        :modalData="brochures"
      />
      <download-contract-modal
        modalId="downloadContractModal"
        modalTitle="Contracts"
        
      />
      <resource-common-modal
        modalId="resourcesInfoModal"
        modalTitle="INFORMATION/HOW TO'S"
        :modalData="info"
      />
      <resource-report-modal :effectiveDates="effectiveDates" />
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import ResourceVideoModal from "./ResourceVideoModal.vue";
import ResourceCommonModal from "./ResourceCommonModal.vue";
import ResourceReportModal from "./ResourceReportModal.vue";
import DownloadContractModal from "./DownloadContractModal.vue";
import DashboardListBlock from "@/components/ui/DashboardListBlock";
export default {
  name: "Resources",
  components: {
    DashboardListBlock,
    ResourceVideoModal,
    ResourceCommonModal,
    ResourceReportModal,
    DownloadContractModal
  },
  data: () => ({
    brochures: [],
    info: [],
    contracts: "",
    modalData: [],
    modalId: "",
    modalTitle: "",
    effectiveDates: "",
    videoCategories:[],
    contractData:[]
  }),
  methods: {
    getEffectiveDates() {
      const url = "v2/member-report/get-effective-date";
      Api.get(url)
        .then((res) => {
          this.effectiveDates = res.data.data;
        })
        .catch(() => {
          this.effectiveDates = [];
        });
    },
    resetModal() {
      [this.modalId, this.modalTitle, this.modalData] = ["", "", []];
    },
    showModal(modalId) {
      // switch (modalId) {
      //   case "resourcesBrochureModal":
      //     this.modalData = this.brochures;
      //     this.modalTitle = "BROCHURES/KITS";
      //     this.modalId = "resourcesBrochureModal";
      //     break;
      //   case "resourcesInfoModal":
      //     this.modalData = this.info;
      //     this.modalTitle = "INFORMATION/HOW TO'S";
      //     this.modalId = "resourcesInfoModal";
      //     break;
      //   default:
      //     this.modalData = this.brochures;
      //     this.modalTitle = "BROCHURES/KITS";
      //     this.modalId = "resourcesBrochureModal";
      // }
      this.$bvModal.show(modalId);
    },
    getAll() {
      const url = "v2/get-resources";
      Api.get(url)
        .then((res) => {
          let data = res.data.resources;
          this.brochures = data.files.filter((el) => {
            if (el.header === "BROCHURES/KITS") {
              return el;
            }
          });
          // this.info = data.filter((el) => {
          //   if (el.header === "INFORMATION/HOW TO'S") {
          //     return el;
          //   }
          // });
          this.info = data.informations;
          this.contracts = data.contracts;
        })
        .catch(() => {
          console.log("Something went wrong");
        });
    },
    getVideoLibrary(){
      const url = "v2/video/category/all";
      Api.get(url).then((res)=>{
        console.log(res.data)
        this.videoCategories = res.data.data;
      })
      .catch(()=>{
        this.videoCategories = []
      })
    }
  },
  getData() {
      const url = `v2/rep-contract/get-signed-contract`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          console.log(data)
          this.contractData= data;
          
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
  created() {
    this.getEffectiveDates()
    this.getAll();
    this.getVideoLibrary();
    // this.getData()

  },
};
</script>
<style>
.modal-header{
  display: block;
  text-align: center;
}
</style>